import * as React from "react";
import Layout from "../_components/Layout/Layout";
import MainTitle from "../_components/MainTitle/MainTitle";
import PageSummary from "../_components/PageSummary/PageSummary";
import Banner from "../_components/Banner/Banner";
import RewardsCarousel from "../_components/RewardsCarousel/RewardsCarousel";
import ClaimRewardBtn from "../_components/ClaimRewardBtn/ClaimRewardBtn";

const EventPromotions = () => {
    return (
        <Layout title="GIFTS AND OFFERS">
            <MainTitle>ENJOY A GIFT FROM US</MainTitle>

            <PageSummary>
                SCAN A QR CODE AROUND THE SITE TO CLAIM YOUR GIFT.
            </PageSummary>

            <ClaimRewardBtn />

            <Banner
                image="https://assets.xela.co/silicon-valley-classic/svm/web/d4bdd265-183e-495e-b52e-cef44f3554ea.jpg"
                text="A GIFT BASKET FROM OUR PARTNERS"
            />

            <Banner
                image="https://assets.xela.co/silicon-valley-classic/svm/web/a4c6e0a6-453f-457d-8388-a35446faab57.jpg"
                text="TENNIS CHANNEL MERCHANDISE"
            />

            <Banner
                image="https://assets.xela.co/silicon-valley-classic/svm/web/09f8df39-11c6-4fcf-a721-3650df6efd00.jpg"
                text="DISCOUNTS AT THE TOURNAMENT STORE"
            />

            {/* <RewardsCarousel></RewardsCarousel> */}

            <ClaimRewardBtn />
        </Layout>
    );
};

export default EventPromotions;
