import Database from "./Database";

export default class Qr {
  // Get query params
  static getQueryParams() {
    const urlParams = typeof window !== "undefined" ? new URLSearchParams(window.location.search) : null;

    console.log(urlParams);

    return {
      clickId: urlParams ? urlParams.get("click_id") : null,
      campaignId: urlParams ? urlParams.get("campaign_id") : null,
      code: urlParams ? urlParams.get("code") : null,
      variant: urlParams ? urlParams.get("variant") : null,
      accessToken: urlParams ? urlParams.get("access_token") : null
    };
  }

  // Check query params
  static hasQueryParams() {
    const qrParams = this.getQueryParams();
    return qrParams && qrParams.game === 'true' 
                  ? qrParams.clickId && qrParams.campaignId && qrParams.code && qrParams.variant
                  : qrParams.campaignId && qrParams.variant
  }

  // Save in DB
  static storeParams() {
    const qrParams = this.getQueryParams();
    Database.setQrParams(qrParams);
  }

  // Save game payload in DB
  static storeGame(game) {
    Database.setGameParams(game);
  }

  // Save game choices in DB
  static storeChoices(choices) {
    Database.setGameChoices(choices);
  }

  // Load from DB
  static getStoredParams() {
    return Database.getQrParams();
  }

  // Load from DB
  static getGameParams() {
    return Database.getGameParams();
  }

  // Load from DB
  static getGameChoices() {
    return Database.getGameChoices();
  }

  // Remove access token from qr params
  static removeStoredParams() {
    Database.removeQrParams();
  }

  // Remove access token from qr params
  static removeGameParams() {
    Database.removeGameParams();
  }

  // Check query params
  static hasStoredParams() {
    let qrParams = this.getStoredParams();
    return !!(qrParams && qrParams.clickId && qrParams.campaignId && qrParams.code && qrParams.variant);
  }
}
